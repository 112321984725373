import { NextPage } from 'next'
import Head from 'next/head'
import { Box, Container, Stack, Typography } from '@mui/material'
import { Button } from 'ui'
import Image from 'next/image'
import Link from 'next/link'

const NotFound: NextPage = () => {
  return (
    <>
      <Head>
        <title>Could not find Content | Error | HomeOptions</title>
      </Head>
      <Container maxWidth="sm" sx={{ mt: '94px' }}>
        <Stack minHeight="calc(100vh - 94px)" py={4}>
          <Stack
            gap={2}
            alignItems="center"
            bgcolor="#F5F5FE"
            borderRadius={4}
            height={375}
            px={{ xs: 2, sm: 4, md: 8, lg: 16 }}
            pt={8}
          >
            <Typography variant="h2">{`Couldn't find the content`}</Typography>
            <Typography variant="body1" paragraph>
              {`Sorry, we couldn't find the content you were looking for.`}
            </Typography>
            <Box
              position="relative"
              minHeight={300}
              width="100%"
              sx={{
                '& img': {
                  objectFit: 'contain',
                },
              }}
            >
              <Image src="/images/not_qualified.webp" alt="Need time" priority fill sizes="100vw" />
            </Box>
            <Link href="/">
              <Button style={{ minWidth: 160 }} variant="text">
                <u>Back to home</u>
              </Button>
            </Link>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

export default NotFound
